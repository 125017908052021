.navigation .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.navigation .links li {
  font-size: 13px;
  margin-bottom: 1.25rem;
}
@media (min-width: 992px) {
.navigation .links li {
    margin-bottom: 0;
}
}
.navigation .links li a {
  color: #333333;
}
.navigation .links li a:hover {
  text-decoration: underline;
}
.navigation .links li button {
  margin-top: 0.25rem;
  font-size: 11px !important;
}
@media (min-width: 992px) {
.navigation .links {
    margin: 0;
    padding: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.navigation .links li {
    padding: 0 1.4rem;
    position: relative;
}
.navigation .links li:before {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 0;
    width: 1px;
    height: 24px;
    background: #dee2e6;
}
.navigation .links li:first-child:before {
    display: none;
}
.navigation .links li:last-child {
    /*
    margin-right:1.5rem;
    &:after
    {
        content:"";
        position:absolute;
        top:50%;
        transform:translateY(-50%);
        right:0;
        width:1px;
        height:24px;
        background:$gray-300;
    }
    */
}
}
@media (min-width: 992px) and (max-width: 1199.98px) {
.navigation .links li {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
}
.navigation .menuItemWithIcon svg, .navigation .menuItemWithIcon img {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  max-width: 14px;
  height: auto;
  text-decoration: none !important;
  margin-right: 6px;
}
.navigation .menuItemWithIcon svg *, .navigation .menuItemWithIcon img * {
  fill: #dc3545;
}