.user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  font-size: 13px;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #F2F2F2;
}
.user .logo {
  margin: 0 auto;
  margin-top: 0.5rem;
}
.user .logo img {
  max-height: 60px;
  width: auto;
  max-width: 250px;
}
.user .logo .resumeText {
  text-align: left;
  margin-bottom: 6px;
  font-size: 12px;
}
.user .logo .boxsetResume strong {
  font-size: 14px;
}
.user .logo .boxsetResume .boxsetName {
  display: none;
}
.user .logo .boxsetResume .boxsetIcon {
  width: 18px;
  height: 18px;
  -webkit-transform: none;
          transform: none;
}
.user .logo .boxsetResume .salaBadge {
  padding: 0.15rem 0.75rem;
  font-size: 13px;
}
.user .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 1rem;
}
.user .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background: #0077CA;
  background-size: cover;
  background: center center;
}
.user .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.user .logout {
  text-align: right;
  font-size: 10px;
  color: #dc3545;
  cursor: pointer;
}
.user .logout span {
  margin-left: 5px;
}
.user .logout:hover {
  text-decoration: underline;
}
.user .displayName {
  font-weight: bold;
}
@media (min-width: 992px) {
.user {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0;
    padding: 0;
    border-top: 0;
}
.user .logo {
    margin: 0 1.5rem 0 0;
    padding: 0 1.5rem 0 0;
    position: relative;
}
.user .logo:before {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 0;
    width: 1px;
    height: 24px;
    background: #dee2e6;
}
.user .info {
    margin: 0;
    padding: 0;
}
}
@media (min-width: 992px) and (max-width: 1199.98px) {
.user .logo img {
    height: 30px;
}
.user .logo {
    margin: 0 0.5rem 0 0;
    padding: 0 0.5rem 0 0;
}
.user .displayNameWelcome,
.user .creditCopy {
    display: none;
}
.user span {
    white-space: nowrap;
}
}